<template>
  <widget-wrapper
    title="¿Eres un máster Eurekers?"
    widget-id="widget-master-eurekers"
    widget-name="Master Eurekers"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    :has-alternate-action="true"
    alternate-action-icon="launch"
    alternate-action-url="/app/exercises"
    theme="dark"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
  >
    <!-- Loading State -->
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <div v-else class="master-content">
      <!-- Level Display -->
      <div class="level-display">
        <div class="level-info">
          <p class="level-label">Tu nivel es:</p>
          <p class="level-value">{{ exercise_level }}%</p>
        </div>
        <div class="level-chart">
          <div class="level-gauge">
            <div class="gauge-bar-container">
              <div class="gauge-level-markers">
                <div class="level-marker" v-for="n in 5" :key="n"></div>
              </div>
              <div class="gauge-bar">
                <div class="gauge-fill" :style="{ width: exercise_level + '%' }"></div>
                <div class="gauge-indicator" :style="{ left: exercise_level + '%' }">
                  <div class="indicator-dot"></div>
                </div>
              </div>
              <div class="gauge-labels">
                <span>0</span>
                <span>25</span>
                <span>50</span>
                <span>75</span>
                <span>100</span>
              </div>
            </div>
            <div class="level-badge" :class="'level-' + getLevelClass()">
              {{ getLevelNumber() }}
            </div>
          </div>
        </div>
      </div>

      <!-- Exercise Types -->
      <div class="exercises-section">
        <!-- Basic Exercises -->
        <div class="exercise-category">
          <a 
            class="category-button"
            :href="link_to_exercise"
          >
            <span class="category-icon">&#187;</span>
            <span class="category-text">Ejercicios básicos</span>
          </a>
          
          <div class="progress-container">
            <div class="progress-bar">
              <div class="progress-bar-fill" :style="{ width: level_info_basic.level + '%' }"></div>
            </div>
          </div>
          
          <!-- Commented out dropdown functionality
          <div v-if="basic_exercises" class="exercises-list">
            <div 
              v-for="basic_ex in basic_exer" 
              :key="basic_ex"
              class="exercise-item"
            >
              <a :href="link_to_exercise" class="exercise-link">
                {{ "·" + basic_ex.exercise_type + ": " + basic_ex.walkaround }}
              </a>
            </div>
          </div>
          -->
        </div>
        
        <!-- Advanced Exercises -->
        <div class="exercise-category">
          <a 
            class="category-button" 
            :href="link_to_exercise"
          >
            <span class="category-icon">&#187;</span>
            <span class="category-text">Ejercicios avanzados</span>
          </a>
          
          <div class="progress-container">
            <div class="progress-bar">
              <div class="progress-bar-fill" :style="{ width: level_info_advanced.level + '%' }"></div>
            </div>
          </div>
          
          <!-- Commented out dropdown functionality
          <div v-if="advanced_exercises" class="exercises-list">
            <div 
              v-for="advanced_ex in advanced_exer" 
              :key="advanced_ex"
              class="exercise-item"
            >
              <a :href="link_to_exercise" class="exercise-link">
                {{ "·" + advanced_ex.exercise_type + ": " + advanced_ex.walkaround }}
              </a>
            </div>
          </div>
          -->
        </div>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller],
  name: "MasterEurekers",
  components: {
    WidgetWrapper
  },
  data() {
    return {
      loading: true,
      exercise_level: 0,
      basic_exer: [],
      basic_exercises: false,
      advanced_exer: [],
      advanced_exercises: false,
      widget_name: { id: "widget-master-eurekers", name: "Master Eurekers" },
      master_eurekers_info: 0,
      basic_walkarounds_split: [],
      advanced_walkarounds_split: [],
      basic_divisions: [],
      advanced_divisions: [],
      level_info_basic: "",
      level_info_advanced: "",
      link_to_exercise: "",
      url_iframe:
        "https://player.vimeo.com/video/187305097?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre máster Eurekers",
    };
  },
  mounted() {
    this.link_to_exercise = this.$detectEnvironment() + "/app/exercises";
    this._getMasterEurekeres();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getMasterEurekeres() {
      let success = (response) => {
        this.basic_exer = response.data.exers_info.basic;
        this.advanced_exer = response.data.exers_info.advanced;
        this.level_info_basic = response.data.level_info.exercises_basic;
        this.level_info_advanced = response.data.level_info.exercises_advanced;

        for (var i = 0; i < this.basic_exer.length; i++) {
          this.basic_walkarounds_split.push(
            this.basic_exer[i].walkaround.split("/")
          );
        }

        for (var j = 0; j < this.advanced_exer.length; j++) {
          this.advanced_walkarounds_split.push(
            this.advanced_exer[j].walkaround.split("/")
          );
        }

        this._level_user();
        this.loading = false;
      };

      let url = "/api/v1/widgets/get-master-eurekers";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "147",
        "Error recuperando masterclass."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },

    _level_user: function() {
      this.exercise_level = (
        this.level_info_basic.level * this.level_info_basic.factor +
        this.level_info_advanced.level * this.level_info_advanced.factor
      ).toFixed(2);
    },

    _exercises_level() {
      var n_basic_questions = 12;
      var n_advanced_questions = 5;

      for (var i = 0; i < this.basic_walkarounds_split.length; i++) {
        var element_i = this.basic_walkarounds_split[i];
        var div_i = element_i[0] / element_i[1];
        this.basic_divisions.push(div_i);
      }

      for (var j = 0; j < this.advanced_walkarounds_split.length; j++) {
        var element_j = this.advanced_walkarounds_split[j];
        var div_j = element_j[0] / element_j[1];
        this.advanced_divisions.push(div_j);
      }

      this._helper_operator(n_basic_questions, n_advanced_questions);
    },

    _helper_operator(n_basic, n_advanced) {
      var suma_basic = 0;
      var suma_advanced = 0;

      for (var i = 0; i < this.basic_divisions.length; i++) {
        suma_basic += this.basic_divisions[i] / n_basic;
      }

      suma_basic = suma_basic * 0.3;

      for (var j = 0; j < this.advanced_divisions.length; j++) {
        suma_advanced += this.advanced_divisions[j] / n_advanced;
      }

      suma_advanced = suma_advanced * 0.7;

      this.exercise_level =
        Math.round(
          ((suma_basic * n_basic * 100 + suma_advanced * n_advanced * 100) /
            (n_basic + n_advanced) +
            Number.EPSILON) *
            100
        ) / 100;
    },

    getLevelClass() {
      if (this.exercise_level <= 5) return 0;
      if (this.exercise_level > 5 && this.exercise_level <= 25) return 1;
      if (this.exercise_level > 25 && this.exercise_level <= 50) return 2;
      if (this.exercise_level > 50 && this.exercise_level <= 75) return 3;
      if (this.exercise_level > 75 && this.exercise_level <= 95) return 4;
      return 5;
    },

    getLevelNumber() {
      if (this.exercise_level <= 5) return 0;
      if (this.exercise_level > 5 && this.exercise_level <= 25) return 1;
      if (this.exercise_level > 25 && this.exercise_level <= 50) return 2;
      if (this.exercise_level > 50 && this.exercise_level <= 75) return 3;
      if (this.exercise_level > 75 && this.exercise_level <= 95) return 4;
      return 5;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-primary, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.master-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  gap: 20px;
  color: var(--color-text-on-highlight, #FFFFFF);
}

.level-display {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
}

.level-info {
  flex: 1;
}

.level-label {
  font-size: 14px;
  color: var(--color-text-secondary, #BFC2CD);
  margin-bottom: 4px;
}

.level-value {
  font-size: 28px;
  font-weight: 600;
  color: var(--primary, #00aedd);
}

.level-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100px;
}

.level-gauge {
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.gauge-bar-container {
  width: 100%;
  position: relative;
}

.gauge-level-markers {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: -8px;
  
  .level-marker {
    width: 2px;
    height: 6px;
    background-color: var(--color-muted, rgba(255, 255, 255, 0.4));
  }
}

.gauge-bar {
  height: 10px;
  background-color: var(--color-outline, rgba(255, 255, 255, 0.2));
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.gauge-fill {
  height: 100%;
  background: linear-gradient(90deg, var(--primary, #0088cc), var(--primary, #00aedd), var(--primary, #00d4ff));
  border-radius: 5px;
  transition: width 0.6s ease-out;
}

.gauge-indicator {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.6s ease-out;
}

.indicator-dot {
  width: 14px;
  height: 14px;
  background-color: var(--color-text-on-highlight, white);
  border-radius: 50%;
  border: 2px solid var(--primary, #00aedd);
  box-shadow: 0 0 6px rgba(0, 174, 221, 0.7);
}

.gauge-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
  
  span {
    font-size: 10px;
    color: var(--color-text-secondary, rgba(255, 255, 255, 0.5));
  }
}

.level-badge {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  color: var(--color-text-on-highlight, white);
  margin-top: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  
  &.level-0 {
    background-color: #555555;
  }
  
  &.level-1 {
    background-color: var(--primary, #0088cc);
  }
  
  &.level-2 {
    background-color: var(--primary, #00aedd);
  }
  
  &.level-3 {
    background-color: var(--primary, #00cefc);
  }
  
  &.level-4 {
    background-color: var(--primary, #00e1ff);
  }
  
  &.level-5 {
    background-color: #25f5ff;
    box-shadow: 0 0 12px rgba(37, 245, 255, 0.5);
  }
}

.exercises-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
}

.exercise-category {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.category-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: var(--primary, #00aedd);
  font-size: 16px;
  font-weight: 500;
  padding: 4px 0;
  cursor: pointer;
  text-align: left;
  transition: color 0.2s ease;
  text-decoration: none;
  
  &:hover {
    color: #25d4ff;
  }
}

.category-icon {
  font-size: 14px;
}

.progress-container {
  margin: 4px 0;
}

.progress-bar {
  height: 5px;
  background-color: var(--color-outline, rgba(255, 255, 255, 0.2));
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.progress-bar-fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--primary, #00aedd);
  border-radius: 3px;
  transition: width 0.3s ease;
}

.exercises-list {
  max-height: 200px;
  overflow-y: auto;
  margin-left: 12px;
  border-left: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  padding-left: 12px;
}

.exercise-item {
  margin-bottom: 8px;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.exercise-link {
  font-size: 13px;
  color: var(--color-muted, #BFC2CD);
  text-decoration: none;
  transition: color 0.2s ease;
  
  &:hover {
    color: var(--primary, #00aedd);
  }
}
</style>
